import * as React from "react";
import styled from "@emotion/styled";

import { baseTextStyle } from "./elements";

interface Props {
    selected: boolean;
    title: string;
    subtitle: string;
    onClick: () => void;
}

export const PlanButton: React.FunctionComponent<Props> = ({selected, title, subtitle, onClick}) => {
    return (
        <Container type="button" href="#" onClick={(event) => {
            event.preventDefault();
            onClick();
        }}>
            <Radio selected={selected}>
                {selected && <RadioInner />}
            </Radio>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
        </Container>
    );
};

const Container = styled.a`
    display: grid;
    grid-template-areas:
        "radio title"
        ". subtitle";
    grid-template-columns: 16px 1fr;
    grid-template-rows: auto auto;
    justify-items: start;
    width: 100%;
    box-sizing: border-box;

    border: none;
    background: none;

    :focus {
        outline: solid 1px #00ffb3;
        outline-offset: 8px;
    }

    :hover {
        cursor: pointer;
    }

    margin-bottom: 16px;
`;

const Title = styled.div`
    grid-area: title;
    ${baseTextStyle};
    color: white;
    font-weight: bold;
    margin-left: 8px;
`;

const Subtitle = styled.div`
    grid-area: subtitle;
    ${baseTextStyle};
    color: white;
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: 2px;
    margin-left: 8px;
`;

const Radio = styled.div<{selected: boolean}>`
    position: relative;
    grid-area: radio;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    border-radius: 8px;
    border: ${props => props.selected ? `none` : `solid 2px #9c9d9e`};
    background: ${props => props.selected ? `#00ffb3` : `none`};
    margin-top: 4px;
`;

const RadioInner = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #111213;
`;