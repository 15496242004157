import * as React from "react";
import { StripeProvider, Elements } from "react-stripe-elements";
import { gtagPageView } from "../../analytics";
import useMount from "react-use/lib/useMount";
import { useConfig } from "@fitplan/context/lib-es/config";
import { useAuthContext } from "@fitplan/context/lib-es/auth";
import { IStripePlanDisplay, IStripeCoupon, IStripeInvoice } from "../../types";
import axios from "axios";

import { InjectedForm } from "./InjectedForm";
import { Text, Header, Margin } from "./elements";
import { PromoInfo } from "./PromoInfo";
import { PromoInput } from "./PromoInput";
import { getCouponDiscountDisplay, getPaymentRequestAmount } from "../../utils/stripe";
import { useTatari } from "../../analytics/useTatari";

export interface Props {
    allowCoupon: boolean;
    couponCode?: string;
    redemptionCode?: string;
    selectedPlan?: IStripePlanDisplay;
    selectedInvoice?: IStripeInvoice;
    disabled: boolean;
    onComplete: () => void;
    language: "en" | "es";
}

export const PaymentForm: React.FunctionComponent<Props> = ({
    allowCoupon,
    selectedPlan,
    selectedInvoice,
    disabled,
    onComplete,
    couponCode,
    language,
    redemptionCode,
    ...props
}) => {
    const [stripe, setStripe] = React.useState<any>(null);
    const [currentCoupon, setCurrentCoupon] = React.useState<IStripeCoupon | null>(null);
    const { isProduction, stripePublicKey, apiBaseUrl } = useConfig();
    const {access_token} = useAuthContext();
    const { tatariTrack } = useTatari();
    useMount(() => {
        setStripe((window as any).Stripe(stripePublicKey));
        gtagPageView("/account/subscribe/payment", undefined, isProduction);
        tatariTrack("pageview", { path: "/account/subscribe/payment", selectedPlan, selectedInvoice });
    });
    React.useEffect(() => {
        if (access_token && couponCode && !currentCoupon) {
            axios({
                method: "get",
                url: `${apiBaseUrl}/web/stripe/validate/coupon?couponCode=${couponCode}`,
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            }).then((result: any) => {
                if (result?.data?.result) {
                    setCurrentCoupon(result.data.result);
                }
            });
        }
    }, [access_token, couponCode]);
    const paymentRequest = React.useMemo(() => {
        if (!stripe) {
            return null;
        }
        if (selectedPlan) {
            return stripe.paymentRequest({
                country: "US",
                currency: selectedPlan.currency,
                total: {
                    amount: getPaymentRequestAmount(selectedPlan, currentCoupon),
                    label: selectedPlan.intervalDisplayText || "Fitplan Membership",
                },
            });
        } else if (selectedInvoice) {
            return stripe.paymentRequest({
                country: "US",
                currency: selectedInvoice.currency,
                total: {
                    amount: selectedInvoice.amount,
                    label: selectedInvoice.title || "Fitplan Membership",
                },
            });
        }
        return null;
    }, [stripe, selectedPlan, selectedInvoice, currentCoupon]);
    return (
        <>
            <Header disabled={disabled}>2. Enter Payment Info</Header>
            {allowCoupon && !currentCoupon && (
                <Margin>
                    <PromoInput
                        placeholder="Enter Promo Code"
                        buttonText="Apply"
                        errorText="Invalid promo code"
                        disabled={disabled}
                        onApply={(coupon) => setCurrentCoupon(coupon)}
                    />
                </Margin>
            )}
            {allowCoupon && currentCoupon && (
                <PromoInfo
                    code={currentCoupon.id}
                    text={getCouponDiscountDisplay(currentCoupon)}
                    onRemove={() => setCurrentCoupon(null)}
                    disabled={disabled}
                />
            )}
            <Text disabled={disabled}>Choose payment type:</Text>
            {paymentRequest && (
                <StripeProvider stripe={stripe}>
                    <Elements>
                        <InjectedForm
                            disabled={disabled}
                            paymentRequest={paymentRequest}
                            redemptionCode={redemptionCode}
                            selectedPlan={selectedPlan}
                            selectedInvoice={selectedInvoice}
                            currentCoupon={currentCoupon}
                            onComplete={onComplete}
                            language={language}
                            accessToken={access_token}
                            key={`${access_token}_${selectedPlan?.id ?? "invoice"}_${currentCoupon?.id ?? "no-coupon"}`}
                        />
                    </Elements>
                </StripeProvider>
            )}
        </>
    );
};
