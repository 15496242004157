import * as React from "react";
import { IStripePlanDisplay, IStripeInvoice, IStripePlan } from "../../types";
import { useAuthContext } from "@fitplan/context/lib-es/auth";
import { useFbPixel } from "@fitplan/context/lib-es/analytics";
import useMount from "react-use/lib/useMount";
import isAfter from "date-fns/isAfter";
import subHours from "date-fns/subHours";

import { processStripePlan } from "../../utils/stripe";
import { Section, Container, Header } from "./elements";
import { SignUpForm, LoginForm  } from "../UserAuth";
import { UserView } from "./UserView";
import { PlanView } from "./PlanView";
import { PaymentForm } from "./PaymentForm";
import { PlanButton } from "./PlanButton";
import { PlanSelect } from "./PlanSelect";
import { StartPage } from "./StartPage";
import { SignUpFooter } from "../UserAuth/SignUpForm";
import { LoginFooter } from "../UserAuth/LoginForm";
import { IPromoData } from "pages/subscribe";

export interface NewSubscriptionModalProps {
    partner?: string;
    couponCode?: string;
    allowCoupon: boolean;
    selectedPlan?: IStripePlan;
    selectedInvoice?: IStripeInvoice;
    availablePlans?: IStripePlan[];
    startPage?: React.ReactNode;
    newCustomersOnly?: React.ReactNode;
    redemptionCode?: string;
    language: "en" | "es";
    promoData?: IPromoData;
}

export const NewSubscriptionModal: React.FunctionComponent<NewSubscriptionModalProps> = ({
    allowCoupon,
    availablePlans = [],
    couponCode,
    language,
    selectedInvoice,
    newCustomersOnly,
    redemptionCode,
    startPage,
    promoData,
    ...props
}) => {
    const allStripePlans: IStripePlanDisplay[] = React.useMemo(
        () => availablePlans.map(processStripePlan),
        [availablePlans],
    );
    const selectedPlan: IStripePlanDisplay | null = React.useMemo(
        () => (props.selectedPlan && processStripePlan(props.selectedPlan)) || null,
        [props.selectedPlan],
    );
    const { user } = useAuthContext();
    const fbPixel = useFbPixel();
    const [currentPlan, setCurrentPlan] = React.useState<IStripePlanDisplay | null>(selectedPlan);
    const [complete, setComplete] = React.useState(false);
    const [selectingPlan, setSelectingPlan] = React.useState(!selectedPlan);
    const [newUser, setNewUser] = React.useState(true);
    useMount(() => {
        fbPixel.initCheckout(currentPlan ? currentPlan.id : undefined);
        if (currentPlan) {
            try {
                const { ga } = window as any;
                ga('send', {
                    hitType: 'event',
                    eventAction: 'add-to-cart',
                    eventLabel: currentPlan.id
                });
            } catch (err) {}
        }
        if (user) {
            const dateJoined = new Date(user.dateJoined);
            const cutOff = subHours(new Date(), 24);
            if (isAfter(cutOff, dateJoined)) {
                setNewUser(false);
            }
        }
    });
    if (!newUser && newCustomersOnly) {
        return (
            <Section active standalone>
                {newCustomersOnly}
            </Section>
        );
    }
    const [isSignUp, setIsSignUp] = React.useState<boolean>(true);

    return complete ? (
        <Container>
            <Section active>
                {startPage || <StartPage />}
            </Section>
        </Container>
    ) : (
        <Container>
            <Section active={!user}>
                {user && (
                    <UserView
                        name={`${user.firstName} ${user.lastName}`}
                        email={user.username}
                        avatarUrl={user.profileImage}
                    />
                )}
                {!user && (isSignUp ? (
                    <>
                        <Header>1. Create Account</Header>
                        <SignUpForm partner={props.partner} onComplete={() => {}} />
                        {!newCustomersOnly && <SignUpFooter onClick={() => setIsSignUp(false)} />}
                    </>
                ) : (
                    <>
                        <Header>1. Sign In</Header>
                        <LoginForm onComplete={() => {}} />
                        <LoginFooter onClick={() => setIsSignUp(true) }/>
                    </>
                ))}
            </Section>
            <Section>
                {allStripePlans.length > 0 && (selectingPlan || !currentPlan) && (
                    <PlanSelect
                        showButton={!!currentPlan}
                        buttonText="Cancel"
                        onClick={() => {
                            if (selectingPlan) {
                                setSelectingPlan(false);
                            }
                        }}
                    >
                        {allStripePlans.map(plan => (
                            <PlanButton
                                selected={currentPlan === plan}
                                key={plan.id}
                                title={`${plan.intervalDisplayText} Subscription`}
                                subtitle={`${plan.priceDisplayText} ${plan.intervalDisplaySubText}`}
                                onClick={() => {
                                    setCurrentPlan(plan);
                                    setSelectingPlan(false);
                                    try {
                                        const { ga } = window as any;
                                        ga('send', {
                                            hitType: 'event',
                                            eventAction: 'add-to-cart',
                                            eventLabel: plan
                                        });
                                    } catch (err) {}
                                }}
                            />
                        ))}
                    </PlanSelect>
                )}
                {!selectingPlan && currentPlan && (
                    <PlanSelect
                        showButton={!!user && availablePlans.length > 1}
                        buttonText="Edit"
                        onClick={() => setSelectingPlan(true)}
                    >
                        <PlanView
                            title={`${currentPlan.intervalDisplayText} Subscription`}
                            subtitle={`${currentPlan.priceDisplayText} ${currentPlan.intervalDisplaySubText}`}
                        />
                    </PlanSelect>
                )}
            </Section>
            <Section active={!!(user && !selectingPlan)}>
                <PaymentForm
                    couponCode={couponCode}
                    allowCoupon={allowCoupon}
                    selectedPlan={currentPlan}
                    selectedInvoice={selectedInvoice}
                    key={currentPlan?.id}
                    disabled={!user || selectingPlan || !currentPlan}
                    onComplete={() => {
                        try {
                            fbPixel.startTrial(currentPlan.id, currentPlan.currency, currentPlan.amount);
                            const { ga } = window as any;
                            ga('send', {
                                hitType: 'event',
                                eventAction: 'purchase-complete',
                                eventLabel: currentPlan.id
                            });
                        } catch(err) {};
                        setComplete(true);
                    }}
                    language={language}
                    redemptionCode={redemptionCode}
                />
            </Section>
        </Container>
    );
};
