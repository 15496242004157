import * as React from "react";
import styled from "@emotion/styled";
import axios from "axios";
import { IStripeCoupon } from "../../types";
import { useConfig } from "@fitplan/context/lib-es/config";

import { linkButtonStyle, baseTextStyle, disabledColor } from "./elements";
import { useAuthContext } from "@fitplan/context/lib-es/auth";

interface Props {
    placeholder: string;
    buttonText: string;
    errorText: string;
    disabled?: boolean;
    onApply: (coupon: IStripeCoupon) => void;
}

export const PromoInput: React.FunctionComponent<Props> = ({
    errorText,
    placeholder,
    buttonText,
    disabled,
    onApply,
    ...props
}) => {
    const { apiBaseUrl } = useConfig();
    const { access_token } = useAuthContext();
    const [value, setValue] = React.useState("");
    const [error, setError] = React.useState("");
    const [pending, setPending] = React.useState(false);
    const onSubmit = async () => {
        let coupon: IStripeCoupon | null = null;
        setPending(true);
        try {
            const result = await axios({
                method: "get",
                url: `${apiBaseUrl}/web/stripe/validate/coupon?couponCode=${value}`,
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });
            if (result.data && result.data.result) {
                coupon = result.data.result;
            }
        } catch (err) {
            // TODO: Add sentry reporting
        }
        setPending(false);
        if (coupon && coupon.valid) {
            onApply(coupon);
        } else {
            setError(errorText);
        }
    };
    
    return (
        <form
            onSubmit={async (event) => {
                event.preventDefault();
                await onSubmit();
            }}
        >
            <Container disabled={!!disabled}>
                <Input
                    value={value}
                    onChange={(event) => {
                        setValue(event.target.value);
                        setError("");
                    }}
                    placeholder={placeholder}
                />
                <Button
                    disabled={(!value || disabled || pending)}
                    type="submit"
                    onClick={async (event) => {
                        event.preventDefault();
                        await onSubmit();
                    }}
                >{pending ? "..." : buttonText}</Button>
            </Container>
            {error && <Error>{error}</Error>}
        </form>
    );
};

const Container = styled.div<{disabled: boolean}>`
    display: grid;
    grid-template-areas: 'input btn';
    grid-template-columns: 1fr auto;

    padding-bottom: 2px;
    border-bottom: solid 1px ${(props) => (props.disabled ? disabledColor : `#ccc`)};
`;

const Input = styled.input`
    grid-area: input;
    ${baseTextStyle};

    border: none;
    background: none;

    :focus {
        outline: none;
    }

    ::placeholder {
        color: #757575;
    }
`;

const Button = styled.button`
    grid-area: btn;

    ${linkButtonStyle};
    color: #12b587;

    :active,
    :focus,
    :hover {
        color: #00ffb3;
    }

    :disabled {
        color: #757575;
    }
`;

const Error = styled.div`
    ${baseTextStyle};
    color: #fc172f;

    font-size: 15px;
`;
