import * as React from "react";
import styled from "@emotion/styled";

import { Text, BoldText, linkButtonStyle } from "./elements";

interface Props {
    code: string;
    text: string;
    disabled?: boolean;
    onRemove: () => void;
}

export const PromoInfo: React.FunctionComponent<Props> = ({code, text, disabled, onRemove, ...props}) => {
    return (
        <Container>
            <Code disabled={disabled}>{code}</Code>
            <PromoText disabled={disabled}>{text}</PromoText>
            <Icon disabled={disabled} onClick={onRemove}><CloseIcon /></Icon>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #ececec;
    margin: 18px 0;
    padding: 0 12px;

    display: grid;
    grid-template-areas: "code text close";
    grid-template-columns: auto 1fr auto;
    grid-gap: 8px;
    align-items: stretch;
`;

const Code = styled(BoldText)`
    grid-area: code;
    color: ${props => props.disabled ? "#757575" : "black"};
`;

const PromoText = styled(Text)`
    grid-area: text;
    color: ${props => props.disabled ? "#757575" : "rgba(0, 0, 0, 0.25)"};
`;

const Icon = styled.button`
    grid-area: close;
    ${linkButtonStyle};
`;

const CloseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12">
        <g fill="none" fillRule="evenodd" stroke="#CCC" stroke-linecap="round" stroke-width="2">
            <path d="M10 1.773l-9 9M10 10.773l-9-9"/>
        </g>
    </svg>
);
