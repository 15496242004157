import * as React from "react";
import {
    NewSubscriptionModal,
    NewSubscriptionModalProps,
} from "@fitplan/lib/components/NewSubscriptionModal";
import { useConfig } from "@fitplan/context/lib-es/config";
import { useStaticQuery, graphql } from "gatsby";

import { localeTextGetter } from "../../utils/l10n";
import { AuthFormStrings } from "../strings/AuthStrings";

export const SubscriptionModal: React.FunctionComponent<Pick<
    NewSubscriptionModalProps,
    | "partner"
    | "startPage"
    | "newCustomersOnly"
    | "couponCode"
    | "allowCoupon"
    | "selectedPlan"
    | "selectedInvoice"
    | "availablePlans"
    | "redemptionCode"
    | "promoData"
>> = props => {
    const {
        signUpPage,
        sanitySubscribe: { modal },
    } = useStaticQuery(graphql`
        query ModalQuery {
            signUpPage {
                facebookButton
                googleButton
                firstNamePlaceholder
                lastNamePlaceholder
                emailPlaceholder
                passwordPlaceholder
                repeatPasswordPlaceholder
                radioMaleText
                radioFemaleText
                radioOtherText
                spearatorText
                title
                footerText
                loginLinkText
                loginButton
                unknownError
                facebookError
                googleError
                signUpError
                emailCheckbox
                termsOfService
                privacyPolicy
                accountAgreement
                accountStep
                paymentStep
                downloadStep
            }
            sanitySubscribe {
                modal {
                    changeSubscription {
                        change {
                            _type
                            en
                            es
                        }
                        selection {
                            _type
                            en
                            es
                        }
                        enterPayment {
                            _type
                            en
                            es
                        }
                    }
                    subscription {
                        coupon {
                            enter {
                                _type
                                en
                                es
                            }
                            apply {
                                _type
                                en
                                es
                            }
                            error {
                                _type
                                en
                                es
                            }
                        }
                    }
                    payment {
                        dueAfterTrial {
                            _type
                            en
                            es
                        }
                        change {
                            _type
                            en
                            es
                        }
                        promoExceptionError {
                            _type
                            en
                            es
                        }
                        terms {
                            monthly {
                                _type
                                en
                                es
                            }
                            annual {
                                _type
                                en
                                es
                            }
                            quarterly {
                                _type
                                en
                                es
                            }
                        }
                        error {
                            _type
                            en
                            es
                        }
                        userError {
                            _type
                            en
                            es
                        }
                    }
                    start {
                        success {
                            _type
                            en
                            es
                        }
                        button {
                            _type
                            en
                            es
                        }
                        exit {
                            _type
                            en
                            es
                        }
                    }
                }
            }
        }
    `);
    const { locale } = useConfig();
    const strings = React.useMemo(() => localeTextGetter(modal), [modal]);
    return (
        <AuthFormStrings>
            <NewSubscriptionModal {...props} language={locale as "en" | "es"} />
        </AuthFormStrings>
    );
};
